import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
function LinkedinIcon(props) {
	return (
        <SvgIcon {...props} viewBox="0 0 26 26">
       <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <g clip-path="url(#clip0_2569_85)">
    <path d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z" fill="white"/>
    <path d="M27.5625 0H8.4375C3.7776 0 0 3.7776 0 8.4375V27.5625C0 32.2224 3.7776 36 8.4375 36H27.5625C32.2224 36 36 32.2224 36 27.5625V8.4375C36 3.7776 32.2224 0 27.5625 0Z" fill="#0A66C2"/>
    <path d="M25.9755 30.612H30.0916C30.2408 30.612 30.3839 30.5527 30.4893 30.4473C30.5948 30.3418 30.6541 30.1988 30.6541 30.0496L30.6562 21.3531C30.6562 16.8076 29.6768 13.3138 24.365 13.3138C22.3457 13.2387 20.4415 14.2796 19.414 16.0177C19.409 16.0262 19.4013 16.0328 19.3922 16.0364C19.3831 16.0401 19.373 16.0407 19.3636 16.0382C19.3541 16.0356 19.3457 16.03 19.3397 16.0222C19.3337 16.0145 19.3304 16.0049 19.3303 15.9951V14.2959C19.3303 14.1468 19.271 14.0037 19.1656 13.8982C19.0601 13.7927 18.917 13.7334 18.7678 13.7334H14.8617C14.7125 13.7334 14.5694 13.7927 14.4639 13.8982C14.3584 14.0037 14.2992 14.1468 14.2992 14.2959V30.0487C14.2992 30.1979 14.3584 30.341 14.4639 30.4465C14.5694 30.552 14.7125 30.6112 14.8617 30.6112H18.9775C19.1267 30.6112 19.2697 30.552 19.3752 30.4465C19.4807 30.341 19.54 30.1979 19.54 30.0487V22.2619C19.54 20.0602 19.9576 17.9279 22.6873 17.9279C25.3782 17.9279 25.413 20.4473 25.413 22.4045V30.0495C25.413 30.1986 25.4723 30.3417 25.5778 30.4472C25.6833 30.5527 25.8264 30.612 25.9755 30.612ZM5.34375 8.38505C5.34375 10.0536 6.71723 11.4263 8.38575 11.4263C10.0538 11.4262 11.4265 10.0526 11.4265 8.38448C11.4262 6.71639 10.0534 5.34375 8.38519 5.34375C6.71653 5.34375 5.34375 6.71667 5.34375 8.38505ZM6.32236 30.612H10.4437C10.5928 30.612 10.7359 30.5527 10.8414 30.4472C10.9469 30.3417 11.0062 30.1986 11.0062 30.0495V14.2959C11.0062 14.1468 10.9469 14.0037 10.8414 13.8982C10.7359 13.7927 10.5928 13.7334 10.4437 13.7334H6.32236C6.17318 13.7334 6.0301 13.7927 5.92461 13.8982C5.81912 14.0037 5.75986 14.1468 5.75986 14.2959V30.0495C5.75986 30.1986 5.81912 30.3417 5.92461 30.4472C6.0301 30.5527 6.17318 30.612 6.32236 30.612Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_2569_85">
      <rect width="36" height="36" fill="white"/>
    </clipPath>
  </defs>
</svg>
      </SvgIcon>
	);
  }
  
  export default LinkedinIcon;
  