import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';

function WhatsappIcon(props) {
	return (
        <SvgIcon {...props} viewBox="0 0 26 26">
       <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
  <g clip-path="url(#clip0_1073_2215)">
    <path d="M0.768257 17.7844C0.767413 20.8091 1.56391 23.7625 3.07844 26.3657L0.623413 35.2599L9.79666 32.8733C12.3339 34.2439 15.1766 34.962 18.0654 34.9622H18.073C27.6095 34.9622 35.3724 27.2622 35.3765 17.798C35.3783 13.2119 33.58 8.89942 30.3127 5.65495C27.046 2.41077 22.7014 0.623187 18.0723 0.621094C8.5347 0.621094 0.772335 8.32063 0.768397 17.7844" fill="url(#paint0_linear_1073_2215)"/>
    <path d="M0.150469 17.779C0.149484 20.9125 0.974531 23.9715 2.54306 26.6679L0 35.881L9.50217 33.4088C12.1203 34.8253 15.0681 35.572 18.0676 35.5732H18.0754C27.954 35.5732 35.9958 27.5962 36 17.7932C36.0017 13.0423 34.1387 8.57484 30.7547 5.21414C27.3703 1.85386 22.8703 0.00195349 18.0754 0C8.19506 0 0.154406 7.97581 0.150469 17.779ZM5.80922 26.2035L5.45442 25.6447C3.96295 23.2916 3.17573 20.5723 3.17686 17.7801C3.18009 9.63195 9.86316 3.00279 18.081 3.00279C22.0607 3.00447 25.8007 4.54381 28.6138 7.33674C31.4267 10.13 32.9746 13.843 32.9736 17.7921C32.97 25.9402 26.2867 32.5702 18.0754 32.5702H18.0695C15.3958 32.5688 12.7735 31.8564 10.4867 30.51L9.94247 30.1898L4.30369 31.6567L5.80922 26.2034V26.2035Z" fill="url(#paint1_linear_1073_2215)"/>
    <path d="M13.5953 10.3466C13.2597 9.60661 12.9066 9.59168 12.5875 9.5787C12.3263 9.56754 12.0276 9.56837 11.7292 9.56837C11.4305 9.56837 10.9452 9.67986 10.535 10.1243C10.1243 10.5691 8.96729 11.6441 8.96729 13.8305C8.96729 16.017 10.5722 18.13 10.796 18.4267C11.02 18.723 13.8944 23.3533 18.4467 25.1346C22.2301 26.6149 23 26.3205 23.8211 26.2463C24.6423 26.1723 26.471 25.1716 26.8441 24.1339C27.2175 23.0963 27.2175 22.2069 27.1055 22.021C26.9936 21.8359 26.6949 21.7247 26.247 21.5025C25.799 21.2802 23.5971 20.2051 23.1866 20.0568C22.776 19.9086 22.4774 19.8347 22.1787 20.2796C21.88 20.7239 21.0224 21.7247 20.7609 22.021C20.4998 22.3181 20.2384 22.3551 19.7906 22.1328C19.3425 21.9098 17.9001 21.4411 16.1888 19.9273C14.8574 18.7494 13.9585 17.2947 13.6972 16.8497C13.4359 16.4054 13.6692 16.1646 13.8938 15.9432C14.095 15.744 14.3418 15.4242 14.566 15.1648C14.7893 14.9053 14.8638 14.7201 15.0132 14.4238C15.1627 14.1271 15.0878 13.8676 14.9761 13.6453C14.8638 13.423 13.9935 11.2252 13.5953 10.3466Z" fill="white"/>
  </g>
  <defs>
    <linearGradient id="paint0_linear_1073_2215" x1="1738.28" y1="3464.51" x2="1738.28" y2="0.621094" gradientUnits="userSpaceOnUse">
      <stop stop-color="#1FAF38"/>
      <stop offset="1" stop-color="#60D669"/>
    </linearGradient>
    <linearGradient id="paint1_linear_1073_2215" x1="1800" y1="3588.1" x2="1800" y2="0" gradientUnits="userSpaceOnUse">
      <stop stop-color="#F9F9F9"/>
      <stop offset="1" stop-color="white"/>
    </linearGradient>
    <clipPath id="clip0_1073_2215">
      <rect width="36" height="36" fill="white"/>
    </clipPath>
  </defs>
</svg>
      </SvgIcon>
	);
  }
  
  export default WhatsappIcon;
  